import anime from "animejs";

import { findColorByPath } from "@transitions/TransitionColors";
import { $ } from "@utils/dom";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-transition]";

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-transition__bg', this.el);
  }


  exit({ next }) {
    this.el.classList.remove("pointer-events-none");

    // first, try to match url with our predefined routes
    let color = findColorByPath(next.url);

    // if color is defined, change background to custom color
    if (color) this.bg.style.backgroundColor = color;
    else this.bg.style.removeProperty("background-color");

    return new Promise((resolve) => {
      anime({
        targets: this.bg,
        translateY: ['100%', '0%'],
        duration: 450,
        delay: 150,
        easing: "easeOutCubic",
        complete: () => resolve()
      });

      this.el.classList.remove("visibility-hidden");
    });
  }

  entering() {
    moduleDelays(350, 350);
  }

  enter() {
    return new Promise((resolve) => {
      anime({
        targets: this.bg,
        translateY: ['0%', '-100%'],
        duration: 850,
        easing: "easeOutCubic",
        complete: () => {
          this.el.classList.add("visibility-hidden", "pointer-events-none");

          resolve();
        }
      });
    });
  }
}

export default SiteTransition;
