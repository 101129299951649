import EventEmitter from 'eventemitter2';

import PowerMode from '@core/power-mode';
import { $$ } from './dom';
import { isArray, isNodeList } from './is';
import { on, off } from './listener';
import Viewport from './viewport';


const VIDEO_SELECTOR = 'video[data-required]';
const ELEMENT_NODE_TYPES = {
  1: true,
  9: true,
  11: true
};

class VideosLoaded extends EventEmitter {
  constructor(elem, onAlways = null) {
    super();

    this.hasAnyBroken = false;
    this.isComplete = false;
    this.videos = null;
    this.progressedCount = 0;

    // use elem as selector string
    var queryElem = elem;
    if ( typeof elem == 'string' ) queryElem = [ ...$$( elem ) ];

    // fail if bad element
    if ( !queryElem ) {
      console.error( 'Bad element for videosLoaded ' + ( queryElem || elem ) );
      return;
    }

    // make sure this.elements is an array
    this.elements = isArray(queryElem) || isNodeList(queryElem) ? queryElem : [ queryElem ];

    // bind always listener
    if ( onAlways ) this.on('always', onAlways);

    // collect videos
    this.getVideos();

    // HACK check async to allow time to bind listeners
    setTimeout( this.check.bind( this ) );
  }

  destroy() {
    if( this.videos ) this.videos.forEach(video => video.destroy());

    this.hasAnyBroken = null;
    this.isComplete = null;
    this.videos = null;
    this.progressedCount = null;
    this.elements = null;
  }

  getVideos() {
    // clear videos
    this.videos = [];
  
    // filter & find items if we have an item selector
    this.elements.forEach( this.addElementVideos, this );
  }

  addElementVideos(el) {
    // filter siblings
    if ( el.nodeName == 'VIDEO' ) {
      this.addVideo(el);
      return;
    }
  
    // find children, skip non-element nodes
    const { nodeType } = el;
    if ( !nodeType || !ELEMENT_NODE_TYPES[ nodeType ] ) return;
    
    // find each image to preload
    [ ...$$(VIDEO_SELECTOR, el) ].forEach(video => this.addVideo(video));
  }

  /**
   * @param {Video} video
   */
  addVideo(video) {
    this.videos.push( new LoadingVideo( video ) );
  }

  check() {
    var _this = this;

    this.progressedCount = 0;
    this.hasAnyBroken = false;

    // complete if no videos
    if ( !this.videos.length ) {
      this.complete();
      return;
    }
  
    const onProgress = function( video, elem, message ) {
      // HACK - Chrome triggers event before object properties have changed. #83
      setTimeout(() => {
        _this.progress( video, elem, message );
      });
    }
  
    this.videos.forEach(video => {
      video.once('progress', onProgress);
      video.check();
    });
  }
  progress(video, elem/*, message*/) {
    this.progressedCount++;
    this.hasAnyBroken = this.hasAnyBroken || !video.isLoaded;

    // progress event
    this.emit('progress', this, video, elem);

    // check if completed
    if ( this.progressedCount == this.videos.length ) this.complete();
  }
  complete() {
    this.isComplete = true;

    this.emit(this.hasAnyBroken ? 'fail' : 'done', this);
    this.emit('always', this);
  }
}

class LoadingVideo extends EventEmitter {
  constructor(el) {
    super();

    this.el = el;
    this.isLoaded = false;

    this._onPlay = this._onPlay.bind(this);
    this._onError = this._onError.bind(this);
  }

  destroy() {
    this._unbindEvents();

    this.el = null;
    this.isLoaded = null;

    this._onPlay = null;
    this._onError = null;
  }
  check() {
    if( PowerMode.low ) {
      this.confirm(false, 'powermode-low');
      return;
    }

    this._bindEvents();

    const src = this.el.dataset.src;
    const src_mobile = this.el.dataset.srcMobile;

    if( src || src_mobile ) {
      this.el.setAttribute('src', !src_mobile ? src : (Viewport.width < 768 ? src_mobile : src));
      //this.el.load();
    }
  }
  confirm(isLoaded, message) {
    this.isLoaded = isLoaded;
    this.emit('progress', this, this.el, message);
  }

  _bindEvents() {
    on(this.el, 'abort', this._onError);
    on(this.el, 'error', this._onError);
    on(this.el, 'suspend', this._onError);
    on(this.el, 'canplaythrough', this._onPlay);
  }
  _unbindEvents() {
    off(this.el, 'abort', this._onError);
    off(this.el, 'error', this._onError);
    off(this.el, 'suspend', this._onError);
    off(this.el, 'canplaythrough', this._onPlay);
  }

  _onPlay() {
    this._unbindEvents();
    this.confirm(true, 'play');
  }
  _onError(event) {
    this._unbindEvents();
    this.confirm(false, 'error');
  }
}

export default VideosLoaded;
