import AJAX from "@core/ajax";
import { isArray } from "@utils/is";

export const TRANSITION_COLORS = [];

export const fetchColors = () => {
  AJAX.get("transitions_routes").then((data) => {
    if (data && isArray(data))
      data.forEach(({ regex, color }) => TRANSITION_COLORS.push({ regex: new RegExp(regex), color }));
  });
};

export const findColorByPath = path => {
  // filter matching routes
  const routes = TRANSITION_COLORS.filter(({ regex }) => {
    return path.match(regex);
  });

  // if a route match url, use route color
  if (routes && routes.length > 0) return routes.pop().color;

  return null;
};
