import anime from "animejs";
import "requestidlecallback-polyfill";

import { fetchColors } from "@transitions/TransitionColors";
import { $, html, rect } from "@utils/dom";
import { on, off } from "@utils/listener";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";
const CLASSNAME = "age-gate";
const AGE_19 = ["bc", "nb", "nl", "nt", "ns", "nu", "yk"];
const AGE_21 = ["usa"];

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-loader__bg', this.el);
    this.logo = $('.site-loader__logo', this.el);
    this.wrap = $('.site-loader__ageGate', this.el);
    this.title = $('.site-loader__title', this.el);
    this.form = $('.site-loader__form', this.el);
    this.sorry = $('.site-loader__sorry', this.el);
    this.location = $('select[name="ageGateLocation"]', this.el);
    this.day = $('input[name="ageGateDay"]', this.el);
    this.month = $('input[name="ageGateMonth"]', this.el);
    this.year = $('input[name="ageGateYear"]', this.el);

    this._ageGate = false;
    this._ageIsValid = true;
    this._inputs = [];
    this._readyPromise = null;

    if( this.day ) this._inputs.push(this.day);
    if( this.month ) this._inputs.push(this.month);
    if( this.year ) this._inputs.push(this.year);

    this._onSubmit = this._onSubmit.bind(this);
    this._onBeforeInput = this._onBeforeInput.bind(this);
    this._onInput = this._onInput.bind(this);
  }

  init() {
    // get previous ageGate result
    this._ageGate = localStorage.getItem('ageGate') == 1;

    // if ageGate hasn't been approved before, show ageGate form
    if( !this._ageGate ) {
      this._ageIsValid = false;

      html.classList.add(CLASSNAME);
      this._bindEvents();
      this._animateIn();
    }
  }
  loaded() {
    moduleDelays(350, 350);
  }
  ready() {
    return new Promise((resolve) => {
      this._readyPromise = resolve;

      // if ageGate is validated, animateOut immediately
      if( this._ageIsValid ) this._animateOut();
    });
  }
  done() { window.requestIdleCallback(fetchColors, { timeout: 2000 }); }

  _bindEvents() {
    if( this.form ) on(this.form, 'submit', this._onSubmit);
    if( this._inputs ) {
      on(this._inputs, 'beforeinput', this._onBeforeInput);
      on(this._inputs, 'input', this._onInput);
    }
  }
  _unbindEvents() {
    if( this.form ) off(this.form, 'submit', this._onSubmit);
    if( this._inputs ) {
      off(this._inputs, 'beforeinput', this._onBeforeInput);
      off(this._inputs, 'input', this._onInput);
    }
  }
  _destroy() {
    this._unbindEvents();

    // remove from DOM when completed
    if( this.el && this.el.parentNode ) this.el.parentNode.removeChild(this.el);

    this.el = null;
    this.bg = null;
    this.logo = null;
    this.wrap = null;
    this.title = null;
    this.form = null;
    this.sorry = null;
    this.location = null;
    this.day = null;
    this.month = null;
    this.year = null;

    this._ageGate = null;
    this._ageIsValid = null;
    this._inputs = null;
    this._readyPromise = null;

    this._onSubmit = null;
    this._onBeforeInput = null;
    this._onInput = null;
  }

  _animateIn() {
    const startBCR = rect(this.logo);

    this.el.removeAttribute('aria-hidden');
    this.wrap.removeAttribute('aria-hidden');

    const endBCR = rect(this.logo);
    const y = startBCR.y - endBCR.y;
    const timeline = anime.timeline({ autoplay: false });

    timeline.add({
      targets: this.logo,
      translateY: [y, 0],
      duration: 750,
      easing: 'easeInOutCubic',
      delay: 100,
    }, 0);

    timeline.add({
      targets: [this.title, this.form],
      translateY: [y * 0.6, 0],
      opacity: {
        value: 1,
        duration: 400,
        easing: 'linear',
      },
      duration: 650,
      easing: 'easeOutCubic',
      delay: anime.stagger(50, {start: 400}),
    }, 0);

    timeline.play();
  }
  _animateOut() {
    html.classList.remove(CLASSNAME);

    const timeline = anime.timeline({ autoplay: false, complete: () => this._destroy()});

    timeline.add({
      targets: this._ageGate ? this.logo : [this.logo, this.title, this.form],
      opacity: {
        value: 0,
        duration: 250,
        delay: anime.stagger(50, {start: 150}),
        easing: 'linear',
      },
      translateY: -60,
      duration: 450,
      easing: 'easeInQuad',
      delay: anime.stagger(50),
    }, 0);

    timeline.add({
      targets: this.bg,
      scaleY: 0,
      duration: 850,
      easing: "easeOutCubic",
      begin: () => {
        html.classList.add("--js-ready");

        this._readyPromise();
        this._readyPromise = null;
      },
    }, this._ageGate ? 300 : 400);

    timeline.play();
  }

  _onSubmit(event) {
    if( event ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    // if ageGate is already passed, stop here
    if( this._ageIsValid ) return false;

    // if native form validation is not supported
    if( this.form.reportValidity ){
      // if form validation fails, skip here
      if( !this.form.reportValidity() ) return false;
    }

    const location = this.location.value;
    const minimum_age = this._getMinimumAgeFromLocation(location);
    
    const year = Math.min(new Date().getFullYear(), parseInt(this.year.value));
    const month = Math.min(11, parseInt(this.month.value) - 1);
    const day = Math.min(31, parseInt(this.day.value));
    const birthDate = new Date(year, month, day);
    const age = this._getAgeFromBirthdate(birthDate);

    if( age >= minimum_age ) {
      this._ageIsValid = true;

      // disable form
      this.form.classList.add('pointer-events-none');

      // save ageGate result
      localStorage.setItem('ageGate', 1);

      anime({
        targets: this.sorry,
        opacity: 0,
        duration: 150,
      });

      // if site is ready for action
      if( this._readyPromise ) this._animateOut();      
    } else {
      anime({
        targets: this.sorry,
        opacity: 1,
        duration: 450,
      });

      this.sorry.setAttribute('aria-hidden', false);
    }

    return false;
  }
  _onBeforeInput(event) {
    if( !event || !event.data ) return;

    const data = parseInt(event.data);

    // if data is not a integer, do not insert data into input
    if( !Number.isInteger(data) ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
  _onInput(event) {
    if( !event ) return;

    const input = event.currentTarget;
    const value = input.value;
    const maxlength = parseInt(input.getAttribute('maxlength'));

    // if input has required number of characters, focus to next input
    if( value.length >= maxlength ) {
      // get input index
      const index = this._inputs.indexOf(input);

      // if input is last, stop here
      if( index >= this._inputs.length - 1 ) return;

      // focus to next input
      this._inputs[index + 1].focus();
    }
  }
  _getMinimumAgeFromLocation(location) {
    if( AGE_19.includes(location) ) return 19;
    else if( AGE_21.includes(location) ) return 21;
    else return 18;
  }

  // https://stackoverflow.com/questions/10008050/get-age-from-birthdate
  _getAgeFromBirthdate(birthDate) {
    const today = new Date();
    const m = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
    return age;
  }
}

export default SiteLoader;
