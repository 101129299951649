import Splitting from "splitting";

import { $, $$, body } from "@utils/dom";


const splitByWordsForMaskAnimation = (el, options, ctx) => {
  const { words, lines } = ctx;
  words.forEach(word => word.innerHTML = `<span class="wordText">${word.innerHTML}</span>`);

  if(el.dataset.scrollTarget && lines){
    $(el.dataset.scrollTarget).style.setProperty('--line-total', lines.length);
  }

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'wordsMask',
  key: 'wordsMask',
  depends: ['words', 'lines'],
  split: splitByWordsForMaskAnimation,
});

const splitByWordsForTitleLineBreaksAnimation = (el, options, ctx) => {
  // alter z-index of line if it has a .title-highlight.--marker
  const markers = $$('.title-highlight.--marker', el);
  if( markers ) {
    markers.forEach(marker => {
      const line = marker.closest('.title-breaklines__line');
      if( line ) line.style.zIndex = 0;
    });
  }

  const { words } = ctx;
  let index = 0;

  words.forEach(word => {
    // if word is not part off a .btn__label, set its real index
    if( !word.closest('.btn__label') ) {
      word.style.setProperty('--word-index', index);
      index++;
    }
  });

  return [];
};

// split by words but skip
Splitting.add({
  by: 'wordsTitleLineBreak',
  key: 'wordsTitleLineBreak',
  depends: ['words'],
  split: splitByWordsForTitleLineBreaksAnimation,
});


export default (el = body) => {
  [ ...$$('[data-splitting]') ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "wordsMask";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
